import { Env } from 'interfaces/app-env.enum';
import { FileCategoryEnum } from './enums/file-category.enum';
import { CasinoGamesFilters } from '../casino-games/types';

export type CollectionByKey<T> = {
  [key: string]: T;
};

export const types = Object.freeze({
  PERMISSIONS_MAP_FETCH: '[App] PERMISSIONS_MAP_FETCH',
  USER_SESSION_FETCH: '[App] USER_SESSION_FETCH',
  USER_SESSION_PUT: '[App] USER_SESSION_PUT',
  FILE_UPLOAD: '[App] FILE_UPLOAD',
  LANGUAGES_FETCH: '[App] LANGUAGES_FETCH',
  REGULATIONS_FETCH: '[App] REGULATIONS_FETCH',
  COUNTRIES_FETCH: '[App] COUNTRIES_FETCH',
});

export interface AppState {
  configReady: boolean;
  apiUrl: string;
  build: string;
  appEnv: Env;
  cmsInstances: CmsInstance[];
  azureAD: AzureADState;
  permissionsMap: [];
  languages: LanguageWithIcon[] | null;
  regulations: Regulation[] | null;
  countries: Country[] | null;
  user: User | null;
  currentCompanyInstance: number | null;
  siteId: number | null;
  session: Session | null;
  isDarkMode: boolean;
  isMinimizeMode: boolean;
  isHoveredMode: boolean;
  isBrandsList: boolean;
  isBrandsSettingsList: boolean;
  isMenuAvailable: boolean;
  isLoading: boolean;
  tabView: TabView;
  viewOptions: TabViewOptions;
}

type SortFilters = {
  orderBy?: string;
  orderDirection?: string;
};

export type ViewTabOption = {
  siteId: number;
  regulationId: number;
  brandName: string;
};

export type TabViewOptions = {
  regulationsIds: ViewTabOption | null;
  platformsIds: ViewTabOption | null;
  countriesIds: ViewTabOption | null;
  demoMode: ViewTabOption | null;
};

export type TabView = {
  network: boolean | null;
  selectedCompanyId: number | null;
  selectedCompanyName: string | null;
  selectedInstanceId?: number | null;
  casinoGamesFilters?: CasinoGamesFilters;
  sortFilters: SortFilters;
  selectedViewColumnsIds?: number[];
};

export interface CmsInstance {
  name: string;
  url: string;
  icon: string;
}

export interface AzureADState {
  tenantId: string;
  clientId: string;
}

export type BackendResponse<ResponseType> = {
  data: ResponseType;
} | boolean | null;

/**
 * Helper type for async thunks that do requests to backend with token
 * Accepts a fetch() wrapper that handles auth and a payload
 */
export type AsyncThunkParams<ResponseType, RequestFields = undefined> = {
  execute: (method: string, url: string, data?: RequestFields) => Promise<ResponseType>;
  payload?: RequestFields;
};

export type FileUploadRequest = {
  altText: string;
  siteId?: number | null;
  fileCategoryId: FileCategoryEnum;
  file: File;
};

export type UploadedFile = {
  fileId: number;
  name: string;
  altText: string | null;
  size: number;
  thumbnailUrl: string | null;
  url: string;
  type: string | null;
  siteId: number | null;
  fileCategoryId: number;
};

export enum CompanyTypeEnum {
  NETWORK = 1,
  CUSTOMER = 2,
}

export type Company = {
  companyId: number;
  companyName: string;
  companyType: CompanyTypeEnum;
};

export type Language = {
  languageId: number;
  languageCode: string;
  languageName: string;
  fallbackLanguageId: number | null;
};

export type LanguageWithIcon = Language & {
  icon: Icon | null;
};

export type Icon = {
  fileId: number;
  thumbnailUrl: string | null;
  url: string;
};

export type Country = {
  countryId: number;
  countryCode: string;
  countryName: string;
  regionCode: string | null;
  languageId: number;
  currencyId: number;
};

export type Regulation = {
  regulationId: number;
  regulationName: string;
  regulationCode: string;
};

export type RegulationWithCountry = Regulation & {
  countries: Country[];
};

export type UserSessionResponse = {
  user: User;
  session: SessionResponse;
};

export type User = {
  userId: number;
  userEmail: string;
  userName: string;
  userExtId: string | null;
  userIcon: string;
  userStatus: number;
  companyId: number;
  companyName: string;
  companyType: CompanyTypeEnum;
  roleId: number;
  roleName: string;
};

export type Session = Omit<SessionResponse, 'userId' | 'updatedAt'>;

export type SessionResponse = {
  userSessionId: number;
  userId: number;
  userSessionValue: UserSessionValues;
  updatedAt: string;
};

export type UserSessionValues = {
  isMinimizeMode?: boolean;
  pathname?: string;
  pinnedBrands?: number[];
  currentSiteId: number | null;
};

export type PutSessionResponse = BackendResponse<PutSession>;

export type PutSession = {
  updatedSessionId: number;
};

export type PutUpdateSession = {
  userSessionId: number;
  userSessionValue: UserSessionValues;
};

export type PutUpdateSessionRequest = PutUpdateSession;

export type TranslatableField = Record<string, string>;
