import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  types,
  GetBrandAssets,
  GetBrandAssetsResponse,
  PostBrandAsset,
  PostBrandAssetResponse,
  PatchBrandAsset,
  PatchBrandAssetResponse,
  GetLogosAssetsResponse,
  GetLogosAssetsFields,
  PutLogosRequest,
  DeleteBrandAsset,
  DeleteBrandAssetResponse,
  GetBrandAssetsRequest,
  GetBrandIcons,
  GetBrandIconsResponse,
  GetBrandIconsRequest,
  PutBrandIconsResponse,
  PutIconsRequest,
  PostBrandAssetsRequest,
  PatchBrandAssetsRequest,
  BrandsComponentsSettings,
  GetBrandsComponentsSettingsResponse,
  GetBrandsComponentsSettingsRequest,
  PutBrandsComponentsSettings,
  PutBrandsComponentsSettingsResponse,
  PutBrandsComponentsSettingsRequest,
  PatchBrandsComponentsSettingsResponse,
  PatchBrandsComponentsSettingsRequest,
  PatchBrandsComponentsSettings,
} from './types';
import { AsyncThunkParams } from '../app/types';
import { endpoints } from '../../config/endpoints';
import { BackendResponseService } from '../../services/BackendResponseService';

export const fetchBrandAssets = createAsyncThunk<
GetBrandAssets | null,
AsyncThunkParams<GetBrandAssetsResponse, GetBrandAssetsRequest>
>(
  types.ASSETS_IMAGES_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const params = new URLSearchParams('');

        // optional tabName param
        if (payload.tabName) {
          params.append('tabName', payload.tabName.toString());
        }
        // optional systemGroupId param
        if (payload.systemGroupId) {
          params.append('systemGroupId', payload.systemGroupId.toString());
        }

        if (payload.orderBy) {
          params.append('orderBy', payload.orderBy.toString());
        }

        if (payload.orderDirection) {
          params.append('orderDirection', payload.orderDirection.toString());
        }

        if (payload.companyId) {
          params.append('companyId', payload.companyId.toString());
        }

        if (payload.siteId) {
          params.append('siteId', payload.siteId.toString());
        }

        const urlParams = params.toString() ? `?${params.toString()}` : '';

        const url = `${endpoints.brandAssetsGetImages}${urlParams}`;

        const result = await execute(
          'GET',
          url,
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_FETCH, err);
      throw err;
    }
  },
);

export const fetchBrandIcons = createAsyncThunk<
GetBrandIcons | null,
AsyncThunkParams<GetBrandIconsResponse, GetBrandIconsRequest>
>(
  types.ASSETS_ICONS_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const params = new URLSearchParams('');

        if (payload.tabName) {
          params.append('tabName', payload.tabName.toString());
        }

        const result = await execute(
          'GET',
          `${endpoints.brandAssetsGetIcons.replace(':siteId', payload.siteId)}?${params}`,
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_ICONS_FETCH, err);
      throw err;
    }
  },
);

export const postBrandAsset = createAsyncThunk<
PostBrandAsset | null,
AsyncThunkParams<PostBrandAssetResponse, PostBrandAssetsRequest>
>(
  types.ASSETS_IMAGES_ASSET_POST,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute('POST', endpoints.brandAssetsPostAsset, payload);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_ASSET_POST, err);
      throw err;
    }
  },
);

export const patchBrandAsset = createAsyncThunk<
PatchBrandAsset | null,
AsyncThunkParams<PatchBrandAssetResponse, PatchBrandAssetsRequest>
>(
  types.ASSETS_IMAGES_ASSET_PATCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute('PATCH', endpoints.brandAssetsPatchAsset, payload);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_ASSET_POST, err);
      throw err;
    }
  },
);

export const deleteBrandAsset = createAsyncThunk<
DeleteBrandAsset | null,
AsyncThunkParams<DeleteBrandAssetResponse, { assetId: number }>
>(
  types.ASSETS_IMAGES_ASSET_DELETE,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = (await execute('DELETE', endpoints.brandAssetsDeleteAsset
          .replace(':assetId', payload.assetId.toString()), payload));
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_IMAGES_ASSET_DELETE, err);
      throw err;
    }
  },
);

export const fetchLogosAssets = createAsyncThunk<
GetLogosAssetsFields | null,
AsyncThunkParams<GetLogosAssetsResponse, { siteId: number }>
>(
  types.ASSETS_LOGOS_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute(
          'GET',
          endpoints.brandAssetsGetLogos.replace(':siteId', payload.siteId.toString() as string),
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_LOGOS_FETCH, err);
      throw err;
    }
  },
);

export const putLogosAssets = createAsyncThunk<
void | null,
AsyncThunkParams<void, PutLogosRequest>
>(
  types.ASSETS_LOGOS_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      await execute(
        'PUT',
        endpoints.brandAssetsPutLogos,
        payload,
      );
    } catch (err) {
      console.error(types.ASSETS_LOGOS_PUT, err);
      throw err;
    }
  },
);

export const putBrandIcons = createAsyncThunk<
void | null,
AsyncThunkParams<PutBrandIconsResponse, PutIconsRequest>
>(
  types.ASSETS_ICONS_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      await execute(
        'PUT',
        endpoints.brandAssetsPutIcons,
        payload,
      );
    } catch (err) {
      console.error(types.ASSETS_ICONS_PUT, err);
      throw err;
    }
  },
);


export const fetchBrandsComponentsSettings = createAsyncThunk<
BrandsComponentsSettings | null,
AsyncThunkParams<GetBrandsComponentsSettingsResponse, GetBrandsComponentsSettingsRequest>
>(
  types.ASSETS_GLOBAL_COMPONENTS_FETCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const params = new URLSearchParams('');

        const result = await execute(
          'GET',
          `${endpoints.brandComponentsSettings.replace(':siteId', payload.siteId)}?${params}`,
        );
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_GLOBAL_COMPONENTS_FETCH, err);
      throw err;
    }
  },
);

export const putBrandsComponentsSettings = createAsyncThunk<
PutBrandsComponentsSettings | null,
AsyncThunkParams<PutBrandsComponentsSettingsResponse, PutBrandsComponentsSettingsRequest>
>(
  types.ASSETS_GLOBAL_COMPONENTS_PUT,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute('PUT', endpoints.brandComponentsSettingsPut, payload);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_GLOBAL_COMPONENTS_PUT, err);
      throw err;
    }
  },
);

export const patchBrandsComponentsSettings = createAsyncThunk<
PatchBrandsComponentsSettings | null,
AsyncThunkParams<PatchBrandsComponentsSettingsResponse, PatchBrandsComponentsSettingsRequest>
>(
  types.ASSETS_GLOBAL_COMPONENTS_PATCH,
  async (
    { execute, payload },
  ) => {
    try {
      if (payload) {
        const result = await execute('PATCH', endpoints.brandComponentsSettingsPatch, payload);
        return BackendResponseService.getBackendResponseData(result);
      } else {
        return null;
      }
    } catch (err) {
      console.error(types.ASSETS_GLOBAL_COMPONENTS_PATCH, err);
      throw err;
    }
  },
);