import {
  FC,
  useRef,
} from 'react';
import './InstancesMenu.scss';
import classNames from 'classnames';
import { useOnClickOutside } from 'hooks/use-on-click-outside';

export interface InstanceMenuItem {
  text: string;
  icon: string;
  url: string;
  onClick?: () => void;
}

interface Props {
  items: InstanceMenuItem[];
  toggleMenu: () => void;
  className?: string;
  currentInstanceUrl?: string;
}

export const InstancesMenu: FC<Props> = ({
  items,
  toggleMenu,
  currentInstanceUrl,
  className,
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, toggleMenu);

  return (
    <div className={classNames('instances-menu', className)} ref={ref}>
      {
          items.map(({ text, icon, url,  onClick }) =>
            <div
              className={classNames({
                'instances-menu-item': true,
                'instances-menu-item--active': currentInstanceUrl === url,
              })}
              key={icon}
              role="presentation"
              onClick={() => {
                onClick?.();
                toggleMenu();
              }}
            >
              <div className="instances-menu-item__icon">
                <div
                  style={{ 'background': `var(--${icon}) no-repeat` }}
                  className="instances-menu-item__icon__img"
                ></div>
              </div>
              <div className="instances-menu-item__text">{text}</div>
            </div>)
      }
    </div>
  );
};
