import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  Ref,
} from 'react';
import classNames from 'classnames';

import './Modal.scss';

interface Props extends PropsWithChildren {
  forwardedRef?: Ref<HTMLDivElement>;
  className?: string;
  style?: CSSProperties;
}

export const Modal: FC<Props> = ({
  children,
  forwardedRef,
  className,
  style,
}) => {
  const modalClassName = classNames('modal', className);

  return (
    <div className={modalClassName} ref={forwardedRef} style={style}>
      {children}
    </div>
  );
};
