import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { BrandSettingsFormData } from '../../types';
import {
  useAppActions,
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { useFetchWithMsal } from 'hooks/use-fetch-with-msal';
import { useBrandSettings } from '../../hooks/use-brand-settings';
import { NotificationMessages } from 'components/shared/Notification/types';
import { AppLinks } from 'router/uri';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useNotification } from 'contexts/Notifications/useNotification';
import { Brand } from 'store/brands/types';
import { CompanyTypeEnum } from 'store/app/types';
import { FormikValues } from 'formik';

export const useStatusForm = () => {
  const [formValues, setFormValues] = useState<BrandSettingsFormData | null>(null);
  const dispatch = useAppDispatch();
  const {
    postNewBrand,
    fetchBrands,
    fetchBrandsFull,
    putBrandSettingsGenerals,
    fetchBrandSettings,
    deleteBrand,
  } = useAppActions();
  const { execute } = useFetchWithMsal();
  const navigate = useNavigate();
  const { open } = useNotification();
  const { brandSettings } = useBrandSettings();
  const { siteId = '' } = useParams();
  const { user } = useAppSelector(state => state.app);

  const isNetworkUser = user?.companyType === CompanyTypeEnum.NETWORK;

  const submitNewBrand = useCallback(async (brandPayload: Partial<Brand>) => {
    const result = await dispatch(postNewBrand({
      execute,
      payload: brandPayload,
    }));
    const brand = result.payload as Brand | null;

    if (brand && brand.siteId) {
      open(NotificationMessages.CHANGES_SUCCESS);

      await dispatch(fetchBrands({ execute }));

      navigate(
        `${AppLinks.BRAND_SETTINGS_TABULATOR.replace(':siteId', brand.siteId.toString())}/status`,
        { replace: true },
      );
    } else {
      open(NotificationMessages.CHANGES_ERROR);
    }
  }, [execute]);

  const updateBrand = useCallback(async (values: FormikValues) => {
    const result = await dispatch(
      putBrandSettingsGenerals({
        execute,
        payload: {
          ...values.status,
          redirectToLink: values.status.redirectToLink || null,
          siteId: Number(siteId),
        },
      }),
    );

    if ('error' in result && result.error) {
      open(NotificationMessages.CHANGES_ERROR);
      throw new Error(result.error.message);
    } else if (user) {
      await dispatch(fetchBrandSettings({ execute, payload: { siteId: Number(siteId) } }));
      await dispatch(fetchBrandsFull({ execute, payload: {
        companyId: isNetworkUser ? null : user.companyId,
      } }));
      open(NotificationMessages.CHANGES_SUCCESS);
    }

    return result;
  }, [execute, siteId]);

  const deleteBrandAction = useCallback(async () => {
    const result = await dispatch(
      deleteBrand({ execute, payload: { siteId: Number(siteId) } }),
    );

    if ('error' in result && result.error) {
      open(NotificationMessages.CHANGES_ERROR);
      throw new Error(result.error.message);
    } else {
      open(NotificationMessages.CHANGES_SUCCESS);

      await dispatch(fetchBrands({ execute }));

      navigate(
        AppLinks.BRAND_SETTINGS,
        { replace: true },
      );
    }
  }, [execute, siteId]);

  useEffect(() => {
    if (brandSettings) {
      setFormValues({
        status: {
          casinoId: brandSettings.general.casinoId,
          brandStatus: brandSettings.general.brandStatus,
          brandTemplate: brandSettings.general.brandTemplate,
          brandType: brandSettings.general.brandType,
          androidEnabled: brandSettings.general.androidEnabled,
          iosEnabled: brandSettings.general.iosEnabled,
          redirectToLink: brandSettings.general.redirectToLink || '',
        },
      });
    }
  }, [brandSettings]);

  return {
    formValues,
    submitNewBrand,
    updateBrand,
    deleteBrand: deleteBrandAction,
  };
};
