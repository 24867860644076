import { FC } from 'react';
import './InstancePicker.scss';
import { useToggle } from 'hooks/use-toggle';
import classNames from 'classnames';
import {
  InstanceMenuItem,
  InstancesMenu,
} from '../InstancesMenu/InstancesMenu';

interface Props {
  menuItems: InstanceMenuItem[];
  icon: string;
  instanceName: string;
  isMinimize?: boolean;
  isHovered?: boolean;
  currentInstanceUrl?: string;
}

export const InstancePicker: FC<Props> = ({
  menuItems,
  icon,
  instanceName,
  isHovered,
  isMinimize,
  currentInstanceUrl,
}) => {
  const [isMenu, toggleMenu] = useToggle(false);

  const instanceClassName = classNames({
    'instance-picker__instance': true,
    'instance-picker__instance--minimize': isMinimize,
    'instance-picker__instance--minimize--hovered': isHovered,
  });

  const menuClassName = classNames({
    'instance-picker__menu': true,
    'instance-picker__menu--minimize': isMinimize,
    'instance-picker__menu--minimize--hovered': isHovered && isMenu,
  });

  return (
    <div
      className="instance-picker"
      title="Change CMS instance"
    >
      <div
        className={instanceClassName}
        onClick={toggleMenu}
        role="presentation"
      >
        <div className="instance-picker__instance__icon" style={{ 'background': `var(--${icon})` }}></div>
        <div className="instance-picker__instance__text">{instanceName}</div>

      </div>

      {isMenu && <InstancesMenu
        items={menuItems}
        toggleMenu={toggleMenu}
        className={menuClassName}
        currentInstanceUrl={currentInstanceUrl}
      />}
    </div>
  );
};
