import './Menu.scss';
import classNames from 'classnames';
import {
  MenuItem,
  MenuItemProps,
} from 'components/shared/MenuItem/MenuItem';
import {
  CSSProperties,
  FC,
  useRef,
} from 'react';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import { Separator } from 'components/shared/Separator/Separator';
import { UtilsService } from 'services/UtilsService';
import Scrollbars from 'react-custom-scrollbars-2';

interface Props {
  menuList: MenuItemProps[];
  className?: string;
  toggleMenu?: () => void;
  height?: number;
  style?: CSSProperties;
  itemHeight?: number;
}

export const Menu: FC<Props> = ({
  menuList,
  className,
  toggleMenu = () => {},
  height,
  style,
  itemHeight = 41,
}) => {
  const wrapperRef = useRef(null);
  const maxListCount = 8;

  useOnClickOutside(wrapperRef, toggleMenu);

  const itemsCount = menuList.length > maxListCount ? maxListCount : menuList.length;

  const menuCssVars = {
    '--items-count': itemsCount,
    '--item-height': `${itemHeight}px`,
    '--borders-height': `${itemsCount - 1}px`,
  } as CSSProperties;

  return (
    <div
      className={classNames('menu-container', className)}
      ref={wrapperRef}
      style={{
        ...style,
        'maxHeight': `${height}px`,
      }}
      >
      <div
        className="menu-items"
        style={menuCssVars}
        >
        <Scrollbars
          autoHide
          autoHeightMax={314}
          autoHideDuration={500}
          hideTracksWhenNotNeeded
          >
          {menuList.map(({ id,
            text,
            icon,
            colorIcon,
            isDangerOperation,
            onClick,
            menuItem,
            menuItemClassName,
            brandIcon },
          index) =>
            <div key={id ?? index}>
              <MenuItem
                text={text}
                icon={icon}
                colorIcon={colorIcon}
                isDangerOperation={isDangerOperation}
                onClick={onClick}
                toggleMenu={toggleMenu}
                menuItem={menuItem}
                menuItemClassName={menuItemClassName}
                brandIcon={brandIcon}
              />
              {UtilsService.isSeparatorAvailable(index, menuList) && <Separator className="no-margin" />}
            </div>,
          )}
        </Scrollbars>
      </div>
    </div>
  );
};
