export const getRoutesPath = () => {
  return {
    home: AppLinks.HOME,
    promotions: AppLinks.PROMOTIONS,
    articles: AppLinks.ARTICLES,
    casinoGames: AppLinks.CASINO_GAMES,
    casinoGameEditor: AppLinks.CASINO_GAME_EDITOR,
    casinoGamesGameCategoryEditor: AppLinks.CASINO_GAMES_GAME_CATEGORY_EDITOR,
    casinoGamesTabulatorTab: AppLinks.CASINO_GAMES_TABULATOR_TAB,
    casinoGamesTabulatorSubTab: AppLinks.CASINO_GAMES_TABULATOR_SUB_TAB,
    regulations: AppLinks.REGULATIONS,
    regulationsTabulatorTab: AppLinks.REGULATIONS_TABULATOR_TAB,
    brandSettings: AppLinks.BRAND_SETTINGS,
    brandSettingsTabulator: AppLinks.BRAND_SETTINGS_TABULATOR,
    brandSettingsTabulatorTab: AppLinks.BRAND_SETTINGS_TABULATOR_TAB,
    brandsContent: AppLinks.BRANDS_CONTENT,
    networkMedia: AppLinks.NETWORK_MEDIA,
    networkMediaTabulatorTab: AppLinks.NETWORK_MEDIA_TABULATOR_TAB,
    networkMediaTabulatorSubTab: AppLinks.NETWORK_MEDIA_TABULATOR_SUB_TAB,
    networkEditor: AppLinks.NETWORK_EDITOR,
    networkEditorTabulatorTab: AppLinks.NETWORK_EDITOR_TABULATOR_TAB,
    networkEditorTabulatorSubTab: AppLinks.NETWORK_EDITOR_TABULATOR_SUB_TAB,
    users: AppLinks.USERS,
    usersTabulatorTab: AppLinks.USERS_TABULATOR_TAB,
    usersTabulatorSubTab: AppLinks.USERS_TABULATOR_SUB_TAB,
    assets: AppLinks.ASSETS,
    assetsTabulator: AppLinks.ASSETS_TABULATOR,
    assetsTabulatorTab: AppLinks.ASSETS_TABULATOR_TAB,
    assetsTabulatorSubTab: AppLinks.ASSETS_TABULATOR_SUB_TAB,
    experience: AppLinks.EXPERIENCE,
    experienceTabulator: AppLinks.EXPERIENCE_TABULATOR,
    experienceTabulatorTab: AppLinks.EXPERIENCE_TABULATOR_TAB,
    menus: AppLinks.MENUS,
    menusTabulator: AppLinks.MENUS_TABULATOR,
    menusTabulatorTab: AppLinks.MENUS_TABULATOR_TAB,
    menusTabulatorSubTab: AppLinks.MENUS_TABULATOR_SUB_TAB,
    browsePages: AppLinks.BROWSE_PAGES,
    landingPages: AppLinks.LANDING_PAGES,
    widgets: AppLinks.WIDGETS,
    seo: AppLinks.SEO,
    jackpots: AppLinks.JACKPOTS,
    sportsbook: AppLinks.SPORTSBOOK,
    bingo: AppLinks.BINGO,
    lottery: AppLinks.LOTTERY,
    notifications: AppLinks.NOTIFICATIONS,
  };
};

export enum AppLinks {
  HOME = '/',
  PROMOTIONS = '/promotions',
  ARTICLES = '/articles',
  CASINO_GAMES = '/casino-games',
  CASINO_GAME_EDITOR = '/casino-games/game/:entityId/:currentCompanyInstance?',
  CASINO_GAMES_TABULATOR_TAB = '/casino-games/:tab',
  CASINO_GAMES_TABULATOR_SUB_TAB = '/casino-games/:tab/:subtab',
  CASINO_GAMES_GAME_CATEGORY_EDITOR = '/casino-games/games-categories/:entityId',
  REGULATIONS = '/regulations',
  REGULATIONS_TABULATOR_TAB = '/regulations/:tab',
  BRAND_SETTINGS = '/brand-settings',
  BRAND_SETTINGS_TABULATOR = '/brand-settings/:siteId',
  BRAND_SETTINGS_TABULATOR_TAB = '/brand-settings/:siteId/:tab',
  BRANDS_CONTENT = '/brands-content',
  NETWORK_MEDIA = '/network-media',
  NETWORK_MEDIA_TABULATOR_TAB = '/network-media/:tab',
  NETWORK_MEDIA_TABULATOR_SUB_TAB = '/network-media/:tab/:subtab',
  NETWORK_EDITOR = '/network-editor',
  NETWORK_EDITOR_TABULATOR_TAB = '/network-editor/:tab',
  NETWORK_EDITOR_TABULATOR_SUB_TAB = '/network-editor/:tab/:subtab',
  USERS = '/cms-users',
  USERS_TABULATOR_TAB = '/cms-users/:tab',
  USERS_TABULATOR_SUB_TAB = '/cms-users/:tab/:subtab',
  ASSETS = '/assets',
  ASSETS_TABULATOR = '/assets/:siteId',
  ASSETS_TABULATOR_TAB = '/assets/:siteId/:tab',
  ASSETS_TABULATOR_SUB_TAB = '/assets/:siteId/:tab/:subtab',
  EXPERIENCE = '/experience',
  EXPERIENCE_TABULATOR = '/experience/:siteId',
  EXPERIENCE_TABULATOR_TAB = '/experience/:siteId/:tab',
  MENUS = '/menus',
  MENUS_TABULATOR = '/menus/:siteId',
  MENUS_TABULATOR_TAB = '/menus/:siteId/:tab',
  MENUS_TABULATOR_SUB_TAB = '/menus/:siteId/:tab/:subtab',
  BROWSE_PAGES = '/browse-pages',
  LANDING_PAGES = '/landing-pages',
  WIDGETS = '/widgets',
  MINIGAMES = '/minigames',
  LOYALTY = '/loyalty',
  SEO = '/seo',
  JACKPOTS = '/jackpots',
  SPORTSBOOK = '/sportsbook',
  BINGO = '/bingo',
  LOTTERY = '/lottery',
  NOTIFICATIONS = '/notifications',
}
