import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  AppState,
  AzureADState,
  CmsInstance,
  Country,
  LanguageWithIcon,
  Regulation,
  TabView,
  TabViewOptions,
  UserSessionResponse,
} from './types';
import {
  fetchLanguages,
  fetchRegulations,
  fetchCountries,
  getPermissionsMap,
  getUserSession,
} from './actions';
import { Env } from 'interfaces/app-env.enum';

const initialState: AppState = {
  configReady: false,
  appEnv: Env.DEV,
  build: '',
  apiUrl: '',
  cmsInstances: [],
  azureAD: {
    tenantId: '',
    clientId: '',
  },
  permissionsMap: [],
  languages: null,
  regulations: null,
  countries: null,
  user: null,
  session: null,
  siteId: null,
  currentCompanyInstance: null,
  isDarkMode: false,
  isMinimizeMode: true,
  isMenuAvailable: true,
  isHoveredMode: true,
  isBrandsList: false,
  isBrandsSettingsList: false,
  isLoading: false,
  tabView: {
    network: null,
    selectedCompanyId: null,
    selectedCompanyName: null,
    selectedInstanceId: null,
    casinoGamesFilters: {
      selectedStatusIds: [],
      selectedPlatformIds: [],
      selectedGamesLockIds: [],
    },
    selectedViewColumnsIds: [],
    sortFilters: {
      orderBy: '',
      orderDirection: '',
    },
  },
  viewOptions: {
    regulationsIds: null,
    platformsIds: null,
    countriesIds: null,
    demoMode: null,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    handleConfigReady(state, action: PayloadAction<boolean>) {
      state.configReady = action.payload;
    },
    fillApiUrl(state, action: PayloadAction<string>) {
      state.apiUrl = action.payload;
    },
    fillBuild(state, action: PayloadAction<string>) {
      state.build = action.payload;
    },
    fillAppEnv(state, action: PayloadAction<Env>) {
      state.appEnv = action.payload;
    },
    fillCmsInstances(state, action: PayloadAction<CmsInstance[]>) {
      state.cmsInstances = action.payload;
    },
    fillAzureEnv(state, action: PayloadAction<AzureADState>) {
      state.azureAD = action.payload;
    },
    handleNavigationMode(state, action: PayloadAction<boolean>) {
      state.isMinimizeMode = action.payload;
    },
    handleHoveredMode(state, action: PayloadAction<boolean>) {
      state.isHoveredMode = action.payload;
    },
    handleBrandsList(state, action: PayloadAction<boolean>) {
      state.isBrandsList = action.payload;
    },
    handleBrandsSettingsList(state, action: PayloadAction<boolean>) {
      state.isBrandsSettingsList = action.payload;
    },
    setIsMenuAvailable(state, action: PayloadAction<boolean>) {
      state.isMenuAvailable = action.payload;
    },
    handleLoadingChange(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setTabView(state, action: PayloadAction<TabView>) {
      state.tabView = action.payload;
    },
    resetTabView(state) {
      state.tabView = {
        ...initialState.tabView,
      };
    },
    setViewOptions(state, action: PayloadAction<TabViewOptions>) {
      state.viewOptions = action.payload;
    },
    handleSetSiteId(state, action: PayloadAction<number>) {
      state.siteId = action.payload;
    },
    clearSiteId(state) {
      state.siteId = null;
    },
    setCurrentCompanyInstance(state, action: PayloadAction<number>) {
      state.currentCompanyInstance = action.payload;
    },
    clearCurrentCompanyInstance(state) {
      state.currentCompanyInstance = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPermissionsMap.fulfilled, (
      state: AppState,
      action: PayloadAction<[] | null>,
    ) => {
      if (action.payload !== null) {
        state.permissionsMap = action.payload;
      }
    });

    builder.addCase(fetchLanguages.fulfilled, (
      state: AppState,
      action: PayloadAction<LanguageWithIcon[] | null>,
    ) => {
      if (action.payload !== null) {
        state.languages = action.payload;
      }
    });

    builder.addCase(fetchRegulations.fulfilled, (
      state: AppState,
      action: PayloadAction<Regulation[] | null>,
    ) => {
      if (action.payload !== null) {
        state.regulations = action.payload;
      }
    });

    builder.addCase(fetchCountries.fulfilled, (
      state: AppState,
      action: PayloadAction<Country[] | null>,
    ) => {
      if (action.payload !== null) {
        state.countries = action.payload;
      }
    });

    builder.addCase(getUserSession.fulfilled, (
      state: AppState,
      action: PayloadAction<UserSessionResponse | null>,
    ) => {
      if (action.payload !== null
          && action.payload.user
          && action.payload?.session
      ) {
        state.user = action.payload.user;
        state.session = {
          userSessionId: action.payload.session.userSessionId,
          userSessionValue: action.payload.session.userSessionValue,
        };
      }
    });
  },
});
