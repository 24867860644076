import { AppLinks } from 'router/uri';
import { PermissionsEnum } from 'auth/enums/permissions.enum';
import { MenusGuardsEnum } from 'auth/enums/menus-guards.enum';

export enum SidebarLinksText {
  PROMOTIONS = 'Promotions',
  ARTICLES = 'Articles',
  REGULATIONS = 'Regulations',
  BRAND_SETTINGS = 'Brand Settings',
  ASSETS = 'Assets',
  EXPERIENCE = 'Experience',
  NETWORK_EDITOR = 'Network Editor',
  CMS_USERS = 'CMS Users',
  MENUS = 'Menus',
  BROWSE_PAGES = 'Browse Pages',
  LANDING_PAGES = 'Landing Pages',
  WIDGETS = 'Widgets',
  SEO = 'SEO',
  CASINO_GAMES = 'Casino Games',
  JACKPOTS = 'Jackpots',
  SPORTSBOOK = 'Sportsbook',
  BINGO = 'Bingo',
  LOTTERY = 'Lottery',
  NOTIFICATIONS = 'Notifications',
  MINIGAMES = 'Minigames',
  NETWORK_MEDIA = 'Network Media',
  LOYALTY = 'Loyalty',
  HOME = 'Home',
}

enum SidebarLinksIcons {
  PROMOTIONS = 'promotions',
  PROMOTIONS_ACTIVE = 'promotions-active',
  ARTICLES = 'articles',
  ARTICLES_ACTIVE = 'articles-active',
  REGULATIONS = 'regulations',
  REGULATIONS_ACTIVE = 'regulations-active',
  BRAND_SETTINGS = 'brand-settings',
  BRAND_SETTINGS_ACTIVE = 'brand-settings-active',
  ASSETS = 'assets',
  ASSETS_ACTIVE = 'assets-active',
  EXPERIENCE = 'experience',
  EXPERIENCE_ACTIVE = 'experience-active',
  NETWORK_EDITOR = 'network-editor',
  NETWORK_EDITOR_ACTIVE = 'network-editor-active',
  CMS_USERS = 'cms-users',
  CMS_USERS_ACTIVE = 'cms-users-active',
  MENUS = 'menus',
  MENUS_ACTIVE = 'menus-active',
  BROWSE_PAGE = 'browse-page',
  BROWSE_PAGE_ACTIVE = 'browse-page-active',
  LANDING_PAGES = 'landing-pages',
  LANDING_PAGES_ACTIVE = 'landing-pages-active',
  WIDGETS = 'widgets',
  WIDGETS_ACTIVE = 'widgets-active',
  SEO = 'seo',
  SEO_ACTIVE = 'seo-active',
  CASINO_GAMES = 'clover',
  CASINO_GAMES_ACTIVE = 'clover-active',
  JACKPOTS = 'jackpots',
  JACKPOTS_ACTIVE = 'jackpots-active',
  SPORTSBOOK = 'soccerball',
  SPORTSBOOK_ACTIVE = 'soccerball-active',
  BINGO = 'bingo',
  BINGO_ACTIVE = 'bingo-active',
  LOTTERY = 'lottery',
  LOTTERY_ACTIVE = 'lottery-active',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_ACTIVE = 'notifications-active',
  MINIGAMES = 'minigames',
  MINIGAMES_ACTIVE = 'minigames-active',
  NETWORK_MEDIA = 'network-media',
  NETWORK_MEDIA_ACTIVE = 'network-media-active',
  LOYALTY = 'loyalty',
  LOYALTY_ACTIVE = 'loyalty-active',
  HOME = 'home',
  HOME_ACTIVE = 'home-active',
}

export interface SidebarItem {
  text: SidebarLinksText;
  icon: SidebarLinksIcons;
  activeIcon?: SidebarLinksIcons;
  url: AppLinks;
  permissions?: string[];
  isBrandItem?: boolean;
  menuGuard?: MenusGuardsEnum;
}

export interface BrandEntries {
  firstBlock: SidebarItem[];
  secondBlock?: SidebarItem[];
}

export const topNavigationBlock: BrandEntries = {
  firstBlock: [
    {
      text: SidebarLinksText.HOME,
      icon: SidebarLinksIcons.HOME,
      activeIcon: SidebarLinksIcons.HOME_ACTIVE,
      url: AppLinks.HOME,
    },
  ],
};

export const defaultEntries: BrandEntries = {
  firstBlock: [
    {
      text: SidebarLinksText.CASINO_GAMES,
      icon: SidebarLinksIcons.CASINO_GAMES,
      activeIcon: SidebarLinksIcons.CASINO_GAMES_ACTIVE,
      url: AppLinks.CASINO_GAMES,
      menuGuard: MenusGuardsEnum.CASINO_GAMES,
    },
    // {
    //   text: SidebarLinksText.JACKPOTS,
    //   icon: SidebarLinksIcons.JACKPOTS,
    //   activeIcon: SidebarLinksIcons.JACKPOTS_ACTIVE,
    //   url: AppLinks.JACKPOTS,
    // },
    // {
    //   text: SidebarLinksText.MINIGAMES,
    //   icon: SidebarLinksIcons.MINIGAMES,
    //   activeIcon: SidebarLinksIcons.MINIGAMES_ACTIVE,
    //   url: AppLinks.MINIGAMES,
    // },
    // {
    //   text: SidebarLinksText.SPORTSBOOK,
    //   icon: SidebarLinksIcons.SPORTSBOOK,
    //   activeIcon: SidebarLinksIcons.SPORTSBOOK_ACTIVE,
    //   url: AppLinks.SPORTSBOOK,
    // },
    // {
    //   text: SidebarLinksText.BINGO,
    //   icon: SidebarLinksIcons.BINGO,
    //   activeIcon: SidebarLinksIcons.BINGO_ACTIVE,
    //   url: AppLinks.BINGO,
    // },
    // {
    //   text: SidebarLinksText.LOTTERY,
    //   icon: SidebarLinksIcons.LOTTERY,
    //   activeIcon: SidebarLinksIcons.LOTTERY_ACTIVE,
    //   url: AppLinks.LOTTERY,
    // },
  ],
  secondBlock: [
    // {
    //   text: SidebarLinksText.PROMOTIONS,
    //   icon: SidebarLinksIcons.PROMOTIONS,
    //   activeIcon: SidebarLinksIcons.PROMOTIONS_ACTIVE,
    //   url: AppLinks.PROMOTIONS,
    // },
    // {
    //   text: SidebarLinksText.NOTIFICATIONS,
    //   icon: SidebarLinksIcons.NOTIFICATIONS,
    //   activeIcon: SidebarLinksIcons.NOTIFICATIONS_ACTIVE,
    //   url: AppLinks.NOTIFICATIONS,
    // },
    // {
    //   text: SidebarLinksText.ARTICLES,
    //   icon: SidebarLinksIcons.ARTICLES,
    //   activeIcon: SidebarLinksIcons.ARTICLES_ACTIVE,
    //   url: AppLinks.ARTICLES,
    // },
    {
      text: SidebarLinksText.REGULATIONS,
      icon: SidebarLinksIcons.REGULATIONS,
      activeIcon: SidebarLinksIcons.REGULATIONS_ACTIVE,
      url: AppLinks.REGULATIONS,
      menuGuard: MenusGuardsEnum.REGULATIONS,
    },
  ],
};

export const networkUserEntries: BrandEntries = {
  firstBlock: [
    {
      text: SidebarLinksText.BRAND_SETTINGS,
      icon: SidebarLinksIcons.BRAND_SETTINGS,
      activeIcon: SidebarLinksIcons.BRAND_SETTINGS_ACTIVE,
      url: AppLinks.BRAND_SETTINGS,
      permissions: [PermissionsEnum.BRANDS_SETTINGS_ENTRY],
    },
    {
      text: SidebarLinksText.NETWORK_MEDIA,
      icon: SidebarLinksIcons.NETWORK_MEDIA,
      activeIcon: SidebarLinksIcons.NETWORK_MEDIA_ACTIVE,
      url: AppLinks.NETWORK_MEDIA,
    },
    {
      text: SidebarLinksText.NETWORK_EDITOR,
      icon: SidebarLinksIcons.NETWORK_EDITOR,
      activeIcon: SidebarLinksIcons.NETWORK_EDITOR_ACTIVE,
      url: AppLinks.NETWORK_EDITOR,
      permissions: [PermissionsEnum.NETWORK_EDITOR_ENTRY],
    },
    {
      text: SidebarLinksText.CMS_USERS,
      icon: SidebarLinksIcons.CMS_USERS,
      activeIcon: SidebarLinksIcons.CMS_USERS_ACTIVE,
      url: AppLinks.USERS,
      permissions: [PermissionsEnum.CMS_USERS_ENTRY],
    },
  ],
};

export const perBrandEntries: BrandEntries = {
  firstBlock: [
    // {
    //   text: SidebarLinksText.BROWSE_PAGES,
    //   icon: SidebarLinksIcons.BROWSE_PAGE,
    //   activeIcon: SidebarLinksIcons.BROWSE_PAGE_ACTIVE,
    //   url: AppLinks.BROWSE_PAGES,
    //   isBrandItem: true,
    // },
    // {
    //   text: SidebarLinksText.LANDING_PAGES,
    //   icon: SidebarLinksIcons.LANDING_PAGES,
    //   activeIcon: SidebarLinksIcons.LANDING_PAGES_ACTIVE,
    //   url: AppLinks.LANDING_PAGES,
    //   isBrandItem: true,
    // },
    // {
    //   text: SidebarLinksText.WIDGETS,
    //   icon: SidebarLinksIcons.WIDGETS,
    //   activeIcon: SidebarLinksIcons.WIDGETS_ACTIVE,
    //   url: AppLinks.WIDGETS,
    //   isBrandItem: true,
    // },
    // {
    //   text: SidebarLinksText.LOYALTY,
    //   icon: SidebarLinksIcons.LOYALTY,
    //   activeIcon: SidebarLinksIcons.LOYALTY_ACTIVE,
    //   url: AppLinks.LOYALTY,
    //   isBrandItem: true,
    // },
    {
      text: SidebarLinksText.ASSETS,
      icon: SidebarLinksIcons.ASSETS,
      activeIcon: SidebarLinksIcons.ASSETS_ACTIVE,
      url: AppLinks.ASSETS,
      isBrandItem: true,
    },
    {
      text: SidebarLinksText.EXPERIENCE,
      icon: SidebarLinksIcons.EXPERIENCE,
      activeIcon: SidebarLinksIcons.EXPERIENCE_ACTIVE,
      url: AppLinks.EXPERIENCE,
      isBrandItem: true,
    },
    {
      text: SidebarLinksText.MENUS,
      icon: SidebarLinksIcons.MENUS,
      activeIcon: SidebarLinksIcons.MENUS_ACTIVE,
      url: AppLinks.MENUS,
      isBrandItem: true,
    },
    // {
    //   text: SidebarLinksText.SEO,
    //   icon: SidebarLinksIcons.SEO,
    //   activeIcon: SidebarLinksIcons.SEO_ACTIVE,
    //   url: AppLinks.SEO,
    //   isBrandItem: true,
    // },
  ],
};
